<template>
  <v-container fluid class="pa-0">
    <v-dialog :value="loadingDialog" persistent max-width="320px">
      <v-list>
        <v-list-tile
          v-for="(item, index) in loadingData"
          :key="index"
          avatar
          :color="item.error ? 'red' : item.color"
        >
          <v-list-tile-avatar>
            <v-progress-circular
              :value="100"
              :size="30"
              :color="item.error ? 'red' : item.color"
              :indeterminate="item.loading"
            ></v-progress-circular>
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-dialog>
    <template v-if="!loadingDialog">
      <v-dialog v-model="dialogForm" persistent max-width="420px">
        <template v-if="dialogForm">
          <good-delete
            v-if="deleteId"
            :id="deleteId"
            @goodsHandle="handleGoodsRender"
            @cancel="closeDialogAdd($event)"
          ></good-delete>
          <sync-good
            v-else-if="editedId && syncDialog"
            :id="editedId"
            @goodsHandle="handleGoodsRender"
            @cancel="closeDialogAdd($event)"
          ></sync-good>
          <good-add
            v-else
            @cancel="closeDialogAdd($event)"
            @goodsHandle="handleGoodsRender"
          ></good-add>
        </template>
      </v-dialog>
      <v-card>
        <v-alert :value="createdSuccess" type="success" class="my-0">
          {{ formAlertTitle }}
        </v-alert>
        <v-alert :value="createdWarning" type="warning" class="my-0">
          {{ formAlertTitle }}
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-card-title>
            <v-layout row wrap>
              <v-flex xs2>
                <v-select
                  label="Тип изменения"
                  :items="typeEdit"
                  :rules="[v => !!v || 'Заполните поле']"
                  item-text="name"
                  item-value="id"
                  v-model.number="dataEdit.purchase_type_id"
                  hide-details
                  class="pr-4"
                ></v-select>
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  label="Закупка"
                  :value="dataEdit.arrival"
                  hide-details
                  class="pr-4"
                  :rules="[
                    v => (v !== '' && v !== undefined) || 'Заполните поле'
                  ]"
                  @change="dataEdit.arrival = exelCalc($event)"
                ></v-text-field>
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  label="Сумма"
                  :value="amount"
                  hide-details
                  class="pr-4"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs1>
                <v-text-field
                  label="Наценка"
                  :value="markup + '%'"
                  hide-details
                  class="pr-4"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs3>
                <v-autocomplete
                  label="Компания"
                  :items="clientsList"
                  :rules="[v => !!v || 'Заполните поле']"
                  item-text="name"
                  item-value="id"
                  v-model.number="dataEdit.client_id"
                  hide-details
                  class="mb-4"
                  no-data-text="Не надено"
                  clearable
                  :search-input.sync="searchClients"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs2>
                <v-btn
                  color="info"
                  :disabled="isSaveButtonDisabled"
                  :loading="btnLoad"
                  @click="submitForm"
                  >Сохранить</v-btn
                >
              </v-flex>
            </v-layout>
          </v-card-title>
        </v-form>
      </v-card>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            prepend-icon="search"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
          <v-flex xs2 class="px-2">
                <v-autocomplete
                  v-if="shouldDisplayStoreSelect"
                  label="Магазин"
                  :items="storeList"
                  item-text="name"
                  item-value="id"
                  v-model.number="filter.store_id"
                  hide-details
                  no-data-text="Не надено"
                  clearable
                  @change="customFilter()"
                ></v-autocomplete>
              </v-flex>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            @click="dialogForm = true"
          >Добавить</v-btn>
          <v-btn
            color="info"
            :to="historyLinkPage"
            @click="handleHistoryButtonClick"
            >История</v-btn
          >
        </v-card-title>
        <v-data-table
          :headers="headersTable"
          :items="goodsList"
          hide-actions
          no-data-text="Товаров не найдено"
          no-results-text="Товаров не найдено"
          :search="search"
          disable-initial-sort
        >
          <template slot="items" slot-scope="props">
            <ListItem
              :props="props"
              @onChange="handleRowItemChange"
              @deleteItem="deleteGood"
              @open-sync-dialog="syncGood"
            />
          </template>
        </v-data-table>
        <v-layout row wrap justify-space-around class="py-2">
          <v-flex xs2 class="px-3">
            <v-text-field
              label="Количество на странице"
              v-model.number="take"
              hide-details
              @change="changeShowElem()"
            ></v-text-field>
          </v-flex>
          <v-flex xs10 class="text-xs-right px-3">
            <v-btn
              small
              color="info"
              class="ml-3"
              :disabled="page === 1"
              @click="prevPage()"
            >
              <v-icon dark>keyboard_arrow_left</v-icon>
            </v-btn>
            <v-btn
              small
              color="info"
              class="ml-3"
              :disabled="goodsList.length < take"
              @click="nextPage()"
            >
              <v-icon dark>keyboard_arrow_right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import axios from 'axios';
import GoodAdd from './add.vue';
import ListItem from './ListItem.vue';
import GoodDelete from './delete.vue';
import SyncGood from './syncGood.vue';

export default {
  name: 'Goods',
  components: {
    GoodAdd,
    ListItem,
    GoodDelete,
    SyncGood,
  },
  data() {
    return {
      loadingData: [
        {
          title: 'Получение товаров',
          error: false,
          loading: true,
          color: 'indigo',
          id: 'goods',
        },
      ],
      typeEdit: [],
      dataEdit: {
        arrival: 0,
        client_id: 0,
        purchase_type_id: 1,
        revaluation: 0,
        markup: 0,
      },
      filter: {
        store_id: 0,
      },
      pagination: {
        sortBy: "id",
        rowsPerPage: -1,
        descending: true,
      },
      take: 20,
      page: 1,
      adminRole: '',
      searchClients: '',
      timerClients: null,
      search: '',
      headersTable: [
        {
          text: 'Остаток',
          align: 'left',
          value: 'stock',
          sortable: false,
        },
        {
          text: 'sync',
          align: 'left',
          value: 'sync_is_active',
          sortable: false,
        },
        {
          text: 'Название',
          align: 'left',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Сортировка',
          align: 'left',
          value: 'sort_index',
          sortable: true,
        },
        {
          text: 'Цена',
          align: 'left',
          value: 'price',
          sortable: false,
        },
        {
          text: 'Кол-во',
          align: 'left',
          value: 'count',
        },
        // {
        //   text: 'Выбрать категорию',
        //   align: 'left',
        //   value: 'count',
        // },
        {
          text: 'Цвет',
          align: 'left',
          value: 'color',
          sortable: false,
        },
        {
          text: '',
          align: 'right',
          value: 'action',
          sortable: false,
        },
        {
          text: "",
          align: "right",
          sortable: false,
          value: "action"
        }
      ],
      syncDialog: false,
      editedId: 0,
      goodsList: [],
      clientsList: [],
      categories: [],
      createdSuccess: false,
      createdWarning: false,
      dialogForm: false,
      deleteId: 0,
      client: {},
      queryName: '',
      skipClientsQuery: true,
      suggestions: [],
      loading: true,
      storeList: [],
      btnLoad: false,
    };
  },
  watch: {
    searchClients(val) {
      const findClient = this.clientsList.find((item) => item.name === val);
      if (findClient) return false;

      if (val && val.length >= 3) {
        if (this.timerClients) clearTimeout(this.timerClients);

        this.timerClients = setTimeout(() => {
          this.getClients(val);
        }, 500);
      } else {
        this.clientsList = [];
      }
    },
  },
  computed: {
    shouldDisplayStoreSelect() {
      // Check conditions for displaying store select
      return (
        (parseInt(localStorage.getItem('store-id')) == 0 && this.adminRole == 'admin')
      );
    },
    // flattenedCategories() {
    //   return this.flattenTree(this.categories);
    // },
    isSaveButtonDisabled() {
      return !this.goodsList.some(g => +g.oldPrice !== +g.price || +g.oldCount !== +g.count);
    },
    historyLinkPage() {
      return '/goods/history/';
    },
    loadingDialog() {
      const loadData = this.loadingData.filter(item => !item.error && !item.loading);
      return loadData.length === this.loadingData.length ? 0 : 1;
    },
    formAlertTitle() {
      if (this.createdSuccess){
        return 'Остатки изменены';
      }
      if (this.createdWarning){
        return 'goods: cannot be blank.';
      }
    },
    amount() {
      switch (this.dataEdit.purchase_type_id) {
        case 2: {
          return this.calculateRevaluation(this.goodsList);
        }
        default: {
          return this.calculateOtherOperations(this.goodsList);
        }
      }
    },
    markup() {
      let markupVal = 0;
      if (this.amount !== 0) {
        markupVal = 100 - ((this.dataEdit.arrival * 100) / this.amount);
      }

      return +markupVal.toFixed(2);
    },
  },
  methods: {
    handleGoodsRender() {
      this.getItems();
    },
    customFilter() {
      this.page = 1;
      this.getItems();
    },
    changeShowElem() {
      localStorage.setItem("countElemPage", this.take);
      this.$store.commit("setCountElemPage", this.take);
      this.page = 1;
      this.getItems();
    },
    prevPage() {
      this.page -= 1;
      this.getItems();
    },
    nextPage() {
      this.page += 1;
      this.getItems();
    },
    getPurchaseTypes() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/purchase-types`;

      axios
        .get(url)
        .then((response) => {
          this.typeEdit = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // getCategoryList() {
    //   const url = 'bit-category';

    //   axios.get(url)
    //     .then(response => {
    //       this.categories = response.data;
    //     })
    //     .catch(error => {
    //       console.error('Error fetching category list:', error);
    //     });
    // },
    // flattenTree(tree) {
    //   let flattened = [];
    //   function traverse(node) {
    //     // Add the ID and name of the current node to the flattened array
    //     flattened.push({
    //       id: node.ID,
    //       name: node.NAME,
    //     });

    //     if (node.Children && node.Children.length > 0) {
    //       // If there are children, recursively traverse them and add their ID and name
    //       node.Children.forEach(child => {
    //         traverse(child);
    //       });
    //     }
    //   }

    //   // Start traversal from the root of the tree
    //   this.categories.forEach(rootNode => {
    //     traverse(rootNode);
    //   });

    //   return flattened;
    // },
    onSelected(item) {
      this.client = item;
      this.dataEdit.company = item.id;
    },
    calculateRevaluation(goodsList) {
      return goodsList.reduce(
        (sum, item) => sum + ((item.price - item.oldPrice) * (item.stock + item.count)),
        0,
      );
    },
    calculateOtherOperations(goodsList) {
      return goodsList.reduce((sum, item) => sum + (item.price * item.count), 0);
    },
    handleHistoryButtonClick() {
      this.$store.commit('clearPurchaseFilter');
    },
    handleRowItemChange({ id, code, value }) {
      const good = this.goodsList.find(item => item.id === id);
      good[code] = value;
    },
    exelCalc(val) {
      /* eslint no-eval: 0 */

      const value = val.length > 1 ? val.replace(/^0+/, '') : val;
      console.log(value);

      // return value;
      return eval(value);
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      if (validate && this.dataEdit.client_id) {
        this.btnLoad = true;
        const propsPurchase = Object.assign({}, this.dataEdit);
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/purchases`;

        propsPurchase.amount = this.amount;
        propsPurchase.markup = this.markup;

        const goodsList = this.goodsList.filter((item) => {
          return (+item.price !== +item.oldPrice || +item.count !== 0);
        });

        propsPurchase.goods = goodsList.map((item) => {
          return {
            id: item.id,
            new_price: +item.price,
            new_stock: +item.count,
          };
        });

        axios
          .post(url, propsPurchase)
          .then((res) => {
            this.createdSuccess = true;
            this.getItems();
            console.log('res', res.data)
            setTimeout(() => {
              this.createdSuccess = false;
              this.btnLoad = false;
              this.createdWarning = false;
            }, 1000);
          })
          .catch((error) => {
            this.createdWarning = true;
            setTimeout(() => {
              this.createdWarning = false;
              this.btnLoad = false;
            }, 1000);
          });
      }
    },
    closeDialog() {
      this.createdSuccess = false;
    },
    closeDialogAdd(update) {
      this.dialogForm = false;
      this.deleteId = 0;
      this.editedId = 0;

      if (update) {
        this.goodsList = [];
        this.take = 0;

        const loadData = this.loadingData.find(item => item.id === 'goods');
        loadData.title = 'Получение товаров!';
        loadData.loading = true;

        this.getItems();
      }
    },
    syncGood(id) {
      this.editedId = +id;
      this.syncDialog = true;
      this.dialogForm = true;
    },
    deleteGood(id) {
      this.deleteId = id;
      this.dialogForm = true;
    },
    getItems() {
      const loadData = this.loadingData.find(item => item.id === 'goods');
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/goods`;

      const propsItem = {
        page: this.page,
        page_limit: this.take,
      };

      Object.keys(this.filter).forEach((key) => {
        if (this.filter[key]) {
          propsItem[key] = this.filter[key];
        }
      });

      if (this.pagination.sortBy) {
        const sort = {};
        sort[this.pagination.sortBy] = (this.pagination.descending) ? 'desc_nulls_last' : 'asc_nulls_last';

        propsItem.order_by = JSON.stringify(sort);
      }

      axios
        .get(url, {
          params: propsItem,
        })
        .then((response) => {
          const items = response.data;
          this.goodsList = items.map((item) => {
            item.count = 0;
            item.oldPrice = item.price;
            return item;
          });

          loadData.title = 'Товары получены!';
          loadData.loading = false;
        })
        .catch((error) => {
          loadData.title = 'Ошибка получения товаров!';
          loadData.error = true;
          console.log(error);
        });
    },
    getStoreList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/store`;

      axios
        .get(url)
        .then((response) => {
          this.storeList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClients(searchVal) {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/clients`;

      axios
        .get(url, {
          params: {
            //client_type: 'legal',
            client_type_id: 4,
            name_or_phone: searchVal,
            page_limit: 999,
          },
        })
        .then((response) => {
          this.clientsList = response.data.map((item) => {
            return {
              name: `${item.name} (${item.phone})`,
              id: item.id,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    // get store_id and admin role form logged in user
    const store_id = parseInt(localStorage.getItem('store-id'))
    this.filter.store_id = store_id
    const adminRole = JSON.parse(localStorage.getItem('user-group')).code
    this.adminRole = adminRole

    this.take = this.$store.getters.getCountElemPage;

    this.getItems();
    // this.getCategoryList();
    this.getPurchaseTypes();
    // this.getStoreList();
  },
};
</script>

<style lang="scss">
.v-table {
  tr:nth-child(even) {
    td {
      background: #f9f9f9;
    }

    &:hover {
      td {
        background: #eee;
      }
    }
  }
}

.scs-small {
  &.v-text-field {
    .v-input__control {
      min-height: 30px;
    }

    input {
      max-height: 30px;
      padding: 5px 0;
    }

    .v-select__selection--comma {
      margin: 6px 4px 6px 0;
    }
  }
}
</style>
