<template>
  <v-card>
    <v-alert
      :value="createdSuccess"
      type="success"
      class="my-0"
    >
      Товар добавлен
    </v-alert>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card-title
        class="px-4"
      >
        <span class="headline">Добавление товара</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-4"
      >
        <v-checkbox
          v-model="editedItem.store_active"
          label="Синхронизация включена"
        ></v-checkbox>
        <v-select
          v-if="shouldDisplayStoreSelect"
          label="Магазин"
          :items="storeList"
          item-text="name"
          item-value="id"
          v-model="editedItem.store_id"
          hide-details
          clearable
          no-data-text="Не надено"
        ></v-select>
        <v-checkbox
          v-model="editedItem.b24_good_show_active"
          v-if="editedItem.store_active"
          label="Выбрать букет на сайте"
        ></v-checkbox>

        <v-autocomplete
          label="Имя букета"
          :items="b24GoodList"
          item-text="name"
          item-value="id"
          v-if="editedItem.b24_good_show_active && editedItem.store_active"
          v-model.number="editedItem.bit_element_id"
          hide-details
          no-data-text="Не надено"
          clearable
          :search-input.sync="searchB24Good"
        ></v-autocomplete>

        <v-autocomplete
          v-if="editedItem.store_active"
          :key="editedItem.bit_category_id.join('-')"
          clearable
          chips
          item-text="name"
          item-value="id"
          label="Выбрать категорию"
          v-model="editedItem.bit_category_id"
          :items="flattenedCategories"
          hide-details
          no-data-text="Не надено"
          multiple
        ></v-autocomplete>
        <v-text-field
          v-model="editedItem.name"
          v-if="!editedItem.b24_good_show_active"
          label="Название"
          :rules="[v => !!v || 'Заполните поле']"
        ></v-text-field>
        <v-text-field
          label="Цена"
          :rules="[v => !!v || 'Заполните поле']"
          v-model.number="editedItem.price"
          type="number"
        ></v-text-field>
        <v-text-field
          label="На складе"
          v-model.number="editedItem.stock"
          type="number"
        ></v-text-field>
        <v-text-field
          label="Сортировка"
          :rules="[v => !!v || 'Заполните поле']"
          v-model.number="editedItem.sort_index"
          type="number"
        ></v-text-field>
        <v-text-field
          label="Цвет"
          v-model.number="editedItem.color"
          type="color"
        ></v-text-field>
        <v-checkbox
          v-model="editedItem.sync_is_active"
          v-if="editedItem.store_active"
          label="Выгрузка на сайт"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions
        class="px-4 pb-4"
      >
        <v-btn
          @click.native="cancel()"
        >Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="submitForm"
        >Сохранить</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      categories: [],
      b24GoodList: [],
      searchB24Good:'',
      editedItem: {
        name: '',
        price: 0,
        stock: 0,
        sort_index: 0,
        color: '#FFF',
        bit_category_id: [],
        sync_is_active: false,
        b24_good_show_active: false,
        store_active: false,
        store_id: 0,
      },
      storeList: [],
      createdSuccess: false,
    };
  },
  watch: {
    'editedItem.bit_element_id': function(newVal, oldVal) {
      // Check if the selected bouquet has a bit_category_id property
      const selectedBouquet = this.b24GoodList.find(item => item.id === newVal);
      this.getB24Bouquets(newVal);
      if (selectedBouquet) {
        // Set the bit_category_id in editedItem
        this.editedItem.bit_category_id = selectedBouquet.bit_category_id;
      } else {
        // Clear the bit_category_id if not available
        // this.editedItem.bit_category_id = [];
      }
    },
    // deep: true,
    searchB24Good(val) {
      const findB24Good = this.b24GoodList.find((item) => item.name === val);
      if (findB24Good) return false;

      if (val && val.length >= 3) {
        if (this.timerBouquet) clearTimeout(this.timerBouquet);

        this.timerBouquet = setTimeout(() => {
          this.getB24Bouquets(val);
        }, 500);
      } else {
        this.b24GoodList = [];
      }
    },
  },
  computed: {
    shouldDisplayStoreSelect() {
      // Check conditions for displaying store select
      return (
        (parseInt(localStorage.getItem('store-id')) == 0 && this.adminRole == 'admin')
      );
    },
    flattenedCategories() {
      return this.flattenTree(this.categories);
    },
  },
  mounted() {
    // get store_id and admin role form logged in user
    const store_id = parseInt(localStorage.getItem('store-id'))
    this.editedItem.store_id = store_id
    const adminRole = JSON.parse(localStorage.getItem('user-group')).code
    this.adminRole = adminRole

    this.getCategoryList();
    this.getStoreList();
  },
  methods: {
    getB24Bouquets(searchVal) {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/bit-bouquet`;

      axios
        .get(url, {
          params: {
            id_or_name: searchVal,
          },
        })
        .then((response) => {
          this.b24GoodList = response.data.map((item) => {
            return {
              name: `${item.BouquetName} (${item.ID})`,
              id: item.ID,
              bit_category_id: item.bitCategoryID
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategoryList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/bit-category`;

      axios.get(url)
        .then(response => {
          this.categories = response.data;
          console.log(this.categories)
        })
        .catch(error => {
          console.error('Error fetching category list:', error);
        });
    },

    getStoreList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/store`;

      axios.get(url)
        .then(response => {
          this.storeList = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    flattenTree(tree) {
      let flattened = [];
      function traverse(node) {
        // Add the ID and name of the current node to the flattened array
        flattened.push({
          id: node.ID,
          name: node.NAME,
        });

        if (node.Children && node.Children.length > 0) {
          // If there are children, recursively traverse them and add their ID and name
          node.Children.forEach(child => {
            traverse(child);
          });
        }
      }

      // Start traversal from the root of the tree
      this.categories.forEach(rootNode => {
        traverse(rootNode);
      });

      return flattened;
    },
    cancel() {
      this.editedItem = {};
      this.createdSuccess = false;
      this.$emit('cancel');
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      if (validate && this.editedItem.sort !=0) {
        const propsGood = Object.assign({}, this.editedItem);
        // const propsItem = {
        //   b24_bouquet_show_active: this.editedItem.b24_bouquet_show_active,
        //   bit_element_id: this.editedItem.bit_element_id,
        //   bit_category_id: this.editedItem.bit_category_id,
        //   sync_is_active: this.editedItem.sync_is_active,
        //   sort: this.editedItem.sort,
        //   name: this.editedItem.bouquet_name,
        //   stock: this.editedItem.stock,
        // };
        
        // if b24_bouquet_show_active is true, bouquet_name is null 
        if(this.editedItem.b24_good_show_active == true){
          propsGood.name = null
        } 
        // if sync_is_active is false, bit_element_id = 0
        if(!this.editedItem.sync_is_active){
          propsGood.bit_element_id = 0
        }
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/goods`;

        axios
          .post(url, propsGood)
          .then(() => {
            this.createdSuccess = true;
            this.$emit('goodsHandle');
            setTimeout(() => {
              this.$emit('cancel', true);
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
