<template>
  <tr>
    <td>
      <v-text-field
        label="Склад"
        solo
        flat
        hide-details
        :value="+props.item.stock + +props.item.count"
        class="scs-small goods-stock"
        disabled
      ></v-text-field>
    </td>
    <td>
      <v-checkbox
        v-model="props.item.sync_is_active"
        hide-details
        disabled
      ></v-checkbox>
    </td>
    <td>
      <v-text-field
        label="Название"
        solo
        flat
        hide-details
        :value="props.item.name"
        class="scs-small"
        :disabled="synIsActive"
        @change="changeProps('name', $event)"
      ></v-text-field>
    </td>
    <td>
      <!-- <v-text-field
        label="Сортировка"
        solo
        flat
        hide-details
        :value="props.item.sort_index"
        class="scs-small"
        @change="changeProps('sort_index', +$event)"
      ></v-text-field> -->
      <v-text-field
        label="Сортировка"
        solo
        flat
        hide-details
        :value="props.item.sort_index"
        class="scs-small"
        @change="changeProps('sort_index', +$event)"
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        label="Цена"
        solo
        flat
        hide-details
        type="number"
        :value="props.item.price"
        
        @input="handleItemChange(props.item.id, 'price', +$event)"
        class="scs-small"
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        label="Кол-во"
        solo
        flat
        hide-details
        type="number"
        :value="props.item.count"
        @input="handleItemChange(props.item.id, 'count', +$event)"
        class="scs-small"
      ></v-text-field>
    </td>
    <!-- <td>
      <v-autocomplete
          clearable
          chips
          item-text="name"
          item-value="id"
          label="Выбрать категорию"
          v-model="props.item.bit_category_id"
          :items="categories"
          hide-details
          no-data-text="Не надено"
          @change="changeProps('bit_category_id', $event)"
          multiple
        ></v-autocomplete>
    </td> -->
    <td>
      <v-icon
        title="Выбрать цвет"
        v-if="!showPicker && $store.getters.getAuthUserGroup.code === 'admin'"
        @click="handleColorPickerButtonClick"
      >
        color_lens
      </v-icon>
      <ChromePicker
        v-model="color"
        v-if="showPicker"
        v-click-outside="changeColor"
      />
    </td>
    <td>
      <v-btn
        flat
        icon
        @click="syncGood(props.item.id)"
        class="mx-0"
        title="Параметры букета на сайте"
      >
        <v-icon>loop</v-icon>
      </v-btn>
      <!-- <v-icon
        @click="deleteItem(props.item.id)"
        class="ml-2"
        v-if="
          props.item.id > 0 &&
            $store.getters.getAuthUserGroup.code === 'admin'
        "
        title="Удалить"
      >
        delete
      </v-icon> -->
    </td>
    <td class="text-xs-right">
      <v-icon
        @click="deleteItem(props.item.id)"
        title="Удалить"
        v-if="
          props.item.id > 0 &&
            $store.getters.getAuthUserGroup.code === 'admin'
        "
      >
        delete
      </v-icon>
    </td>
  </tr>
</template>

<script>
import axios from 'axios';
import ChromePicker from 'vue-color/src/components/Chrome.vue';

export default {
  name: 'ListItem',
  props: {
    props: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data: () => ({
    color: '#FFF',
    showPicker: false,
    bit_category_id: [],
  }),
  components: {
    ChromePicker,
  },
  computed: {
    synIsActive() {
      return this.props.item.sync_is_active;
    },
  },
  methods: {
    changeProps(code, value) {
      const propsItem = Object.assign({}, this.props.item);
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/goods-sort/${propsItem.id}`;
      delete propsItem.id;
      delete propsItem.count;
      if (code === 'price') {
        value = parseInt(value, 10); // Assuming base 10 for integer conversion
      }
      propsItem[code] = value;
      console.log(propsItem)
      axios
        .post(url, propsItem)
        .then((response) => {
          const { count, oldPrice } = this.props.item;
          this.props.item = response.data;
          this.props.item.count = count;
          this.props.item.oldPrice = oldPrice;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleItemChange(id, code, value) {
      this.$emit('onChange', { id, code, value });
    },
    handleColorPickerButtonClick() {
      this.showPicker = true;
    },
    changeColor() {
      if (this.showPicker && this.color.hex) {
        this.changeProps('color', this.color.hex);
      }

      this.showPicker = false;
    },
    deleteItem(id) {
      this.$emit('deleteItem', id);
    },
    syncGood(id) {
      this.$emit('open-sync-dialog', id);
    },
  },
  mounted() {
    this.color = this.props.item.color;
  },
};
</script>

<style scoped>
.goods-stock {
  font-weight: bold;
}
.goods-stock.v-text-field input{
  color: #000;
}
</style>
